header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 50px;
  background-color: rgba(248, 248, 248, .6);
  /* 투명도 해결해야함 */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 68px;
  max-width: 100%;
  z-index: 100;
}

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  /* -webkit-text-decoration-skip: objects; Safari 브라우저 최적화 */
}

.logo {
  font-size: 24px;
  cursor: pointer;
  height: 26px;
  margin-left: 40px;
}

.logo img {
  height: 100%;
}

.menu-container {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.menu {
  margin-left: 40px;
  cursor: pointer;
  font-size: 19px;
  font-weight: 500;
}
/* .menu:hover {
  transform: scale(1.05);
  text-decoration: underline;
} */
.menu.active {
  text-decoration: underline;
}


@media (max-width: 768px) {
  header {
    flex-direction: column;
    align-items: center;
    padding: 20px 0 20px 0;
    box-sizing: border-box;
    height: auto; /* 높이 자동 조정 */
    max-width: 768px;
  }

  .logo {
    margin-bottom: 20px;
    margin-right: 8%;
  }

  .menu-container {
    flex-direction: row;
    justify-content: space-between; /* 메뉴 아이템을 화면 너비에 고르게 분포 */
    width: 100%; /* 메뉴가 전체 너비를 사용하도록 설정 */
    margin: 0 50px;
  }

  .menu {
    margin: 0 auto; /* 좌우 여백 조정 */
    padding: 5px;
    font-size: 15px;
  }
}