.section-datavou {
  margin: 0 auto;
  /* border: 1px solid red; */
  /* background-color: #001D8A;  */
  margin-bottom: 250px;
  padding: 100px 0 200px;
}

.section-datavou .container {
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  /* border: 1px solid yellow; */
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
}

.section-datavou h1 {
  font-size: 75px;
  padding: 0;
  margin: 0;
  font-weight: bolder;
  color: rgb(0, 0, 0);
}

.section-datavou h2 {
  font-size: 50px;
  padding: 0;
  margin: 0;
  color: rgb(0, 0, 0);
}

.section-datavou p {
  font-size: 22px;
  padding: 0;
  margin: 0;
  color: rgb(121, 117, 117);
  text-align: center;
}

.section-datavou .section-pic img {
  width: 600px;
  margin-top: 180px;
  margin-bottom: 180px;
}

/* fade-in 클래스 넣는 것으로 따로 키프레임 없이 간단하게 처리 */
.section-datavou .fade-in {
  opacity: 0;
  transform: scale(0.6) translateY(70px);
  transition: opacity 1.3s ease, transform 1.35s ease;
}
.section-datavou .fade-in.show {
  opacity: 1;
  transform: scale(1) translateY(0);
}

@media (max-width: 768px) {
  .section-datavou {
    max-width: 768px;
    margin-bottom: 150px;
    padding: 5vh 0 8vh;
  }

  .section-datavou .container {
    margin: 0 auto;
    max-width: 100%;
    padding: 20px 0px;
  }
  
  .section-datavou h2 {
    font-size: 26px;
  }
  
  .section-datavou p {
    padding: 0 30px;
    font-size: 15px;
  }
  
  .section-datavou .section-pic img {
    width: 300px;
    margin-top: 60px;
    margin-bottom: 60px;
  }
}