.main-content {
  padding-top: 68px;
  padding-bottom: 10vh; /* 푸터와 겹치지 않도록 하단에 여유 공간 추가 */
  min-height: calc(100vh - 68px); /* 헤더 높이와 푸터 높이를 고려한 조정 */
  position: relative;
}

@media (max-width: 768px) {
  .main-content {
    padding-top: 140px; /* 모바일에서의 변경된 헤더 높이 */
    min-height: calc(100vh - 140px); /* 변경된 헤더 높이 고려 */
    max-width: 768px;
  }
}