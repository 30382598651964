.footer_container{
  background-color: #000;
  padding: 20px;  
  line-height: 1.5;
  letter-spacing: 0.5px;
  margin-top: 25vh;
  width: 100%;
  height: 11vh;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
}

.footer_content {
  text-align: center;
  margin: auto;
}

.footer_container .info {
  color: #8c8c8c;
  font-size: 13px;
  font-weight: 400;
  margin: 5px 0;
}

.footer_container .company {
  color: #e2dddd;
  font-size: 14px;
  font-weight: bold;
  margin: 5px 0;
}

@media (max-width: 768px) {
  .footer_container {
    padding: 20px;
    max-width: 768px;
    height: 14vh;
  }
  .footer_container .company {
    font-size: 12px;
  }
  .footer_container .info {
    font-size: 11px;
  }
}