.main {position: relative;}

.main-logo {
  margin: 250px auto 350px;
  width: 100%;
  height: 280px;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0px;
  position: relative;
}

.main-logo img {
  width: 178px;
  margin: 0 auto;
}


.logobox {
  position: absolute;
  width: 200px;
  inset: 0;
  transform: scale(2.048);
  animation: scaleDown 1.3s ease-out;
}

.logoitem {
  position: absolute;
  inset: 0;
  width: 200px;
  transform: scale(.8) translateY(-34px);
  animation: scaleUp 1.3s ease-out;
}

.main-slogan {
  position: absolute;
  top: 250px;
  font-size: 24px;
  color: #001d8a;
  text-align: center;
  animation: sloganUp 1.9s ease-out;
}

@keyframes scaleDown {
  from {
    transform: scale(3);
    opacity: 0;
  }
  to {
    transform: scale(2.048); /* 최종 크기 */
    opacity: 1;
  }
}
@keyframes scaleUp {
  from {
    transform: scale(.1);
    opacity: 0;
  }
  to {
    transform: scale(.8) translateY(-34px); /* 최종 크기 */
    opacity: 1;
  }
}

@keyframes scaleDown2 {
  from {
    transform: scale(1.5);
    opacity: 0;
  }
  to {
    transform: scale(1.024); /* 최종 크기 */
    opacity: 1;
  }
}
@keyframes scaleUp2 {
  from {
    transform: scale(.1);
    opacity: 0;
  }
  to {
    transform: scale(.4) translateY(-108px); /* 최종 크기 */
    opacity: 1;
  }
}

@keyframes sloganUp {
  0% {
    transform: scale(.1) translateY(100px);
    opacity: 0;
  }
  40% {
    transform: scale(.2);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px); /* 최종 크기 */
    opacity: 1;
  }
}
@keyframes sloganUp2 {
  0% {
    transform: scale(.1) translateY(200px);
    opacity: 0;
  }
  40% {
    transform: scale(.2);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px); /* 최종 크기 */
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .main-logo {
    margin: 150px auto 200px;
    padding: 20px 0px;
    position: relative;
  }
  
  .main-logo img {
    width: 178px;
    margin: 0 auto;
  }  
  
  .logobox {
    position: absolute;
    width: 100px;
    inset: 0;
    transform: scale(1.024);
    animation: scaleDown2 1.3s ease-out;
  }
  
  .logoitem {
    position: absolute;
    inset: 0;
    width: 100px;
    transform: scale(.4) translateY(-108px);
    animation: scaleUp2 1.3s ease-out;
  }

  .main-slogan {    
    top: 165px;
    font-size: 14px;
    animation: sloganUp2 1.9s ease-out;
  }
}

.autoscroll {
  position: fixed;
  top: 75px;
  left: 11px;
  background-color: transparent;
  border: 1px solid darkgray;
  border-radius: 10px;
  font-size: 15px;
  padding: 8px;
  opacity: .4;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
}

.autoscroll:hover {
  transform: translateX(0%);
}

button.link {
  margin-top: 20px;
  border: 1.5px solid rgb(121, 117, 117);
  border-radius: 5px;
  padding: 10px;
  width: 320px;
  font-size: 20px;
  font-weight: 400;
  color: rgb(121, 117, 117);
  background-color: transparent;
  transition: .3s;
}

button.link:hover {
  cursor: pointer;
  scale: .98;
  border: 2.5px solid rgb(121, 117, 117);
  background-color: rgb(121, 117, 117);
  color: white;
  font-weight: 700;
}